import eAnimationType from './eAnimationType';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_SYMBOLS, src: 'assets/animations/symbols/boxing_symbols_pro.json' },
      { type: eAnimationType.EAT_BOXERS, src: 'assets/animations/boxers/connor_skin_pro.json' },
      { type: eAnimationType.EAT_POPUPS, src: 'assets/animations/popups/wins_animations_pro.json' },
      { type: eAnimationType.EAT_COINS, src: 'assets/animations/coins/skeleton.json' },
      { type: eAnimationType.EAT_BONUS_BUTTON, src: 'assets/animations/bonus_button/button_bones_pro.json' },
      { type: eAnimationType.EAT_BONUS_GAME_MULTIPLIERS, src: 'assets/animations/bonus_game/multipliers/bonus_game_bg.json' },
      { type: eAnimationType.EAT_BONUS_GAME_BOXERS, src: 'assets/animations/bonus_game/boxers/bonus_boxers_connor_pro.json' },
      // { type: eAnimationType.EAT_REFERRY, src: 'assets/animations/referry/referry.json' },
      { type: eAnimationType.EAT_BONUS_PURCHASE, src: 'assets/animations/bonus_purchase/purchase_bonuses.json' },
    ];
    // stop load background in mobile version
    if (!PIXI.utils.isMobile.any) this.animationsData.push(
      { type: eAnimationType.EAT_BACKGROUND, src: 'assets/animations/background/bg_animations_pro.json' }
    );
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    this.app = app;
    for (let item of this.animationsData) {
      app.loader.add(item.type, item.src);
    }
    app.loader.load(this.onAnimationLoaded);
  }

  onAnimationLoaded = (_, resources) => {
    this.resources = resources;
    this.onLoadingComplete();
  };

  createAnimation(type) {
    if (!window.OPWrapperService.UserAgentDetector.isOpera) {
      this.resources[type].spineAtlas.pages.forEach(page => {
        page.baseTexture.alphaMode = PIXI.ALPHA_MODES.PMA;
      });
    }

    return new PIXI.spine.Spine(this.resources[type].spineData);
  }
}

export default new AnimationsLoader();
